var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SwContainer',_vm._b({},'SwContainer',{
        stretched: _vm.config.full_height,
        paddingY: _vm.config.block_padding_y,
        bgColor: _vm.config.type_background === 'color'
            ? _vm.config.background_color
            : _vm.config.background_image_color,
        bgImage: _vm.config.background_url_image,
        bgOpacity: _vm.config.type_background === 'color'
            ? _vm.config.background_opacity
            : _vm.config.background_image_opacity,
    },false),[(_vm.styleKey === _vm.VARIANTS.FORM_AND_TEXT)?_c('div',{class:( _obj = {}, _obj[_vm.$style['block']] = true, _obj[_vm.$style['block_with-text']] = true, _obj[_vm.$style['block_reversed']] = _vm.config.content_location_for_form_with_text === 'form_with_text_right', _obj[_vm.$style['block_inverted']] = _vm.config.inverted_font_color, _obj )},[_c('FeedbackForm',{class:_vm.$style['block__form'],attrs:{"pivot-id":_vm.pivotId,"title":_vm.config.form_heading_text,"text":_vm.config.form_text_text,"fields":_vm.config.form_fields || [],"button-text":_vm.config.form_button_label,"button-text-after-submit":_vm.config.form_button_message_after_submit}}),_vm._v(" "),_c('div',{class:_vm.$style['block__content']},[_c('h2',{class:[_vm.$style['block__title'], 'sw-title'],domProps:{"innerHTML":_vm._s(_vm.config.heading_text)}}),_vm._v(" "),_c('div',{class:[_vm.$style['block__text'], 'sw-text'],domProps:{"innerHTML":_vm._s(_vm.config.text_text)}})])],1):_c('div',{class:[
            _vm.$style['block'],
            _vm.$style['block_without-text'] ]},[_c('FeedbackForm',{attrs:{"pivot-id":_vm.pivotId,"title":_vm.config.form_heading_text,"text":_vm.config.form_text_text,"fields":_vm.config.form_fields || [],"button-text":_vm.config.form_button_label,"button-text-after-submit":_vm.config.form_button_message_after_submit}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }