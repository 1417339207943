<template>
    <SwContainer
        v-bind="{
            stretched: config.full_height,
            paddingY: config.block_padding_y,
            bgColor: config.type_background === 'color'
                ? config.background_color
                : config.background_image_color,
            bgImage: config.background_url_image,
            bgOpacity: config.type_background === 'color'
                ? config.background_opacity
                : config.background_image_opacity,
        }"
    >
        <div
            v-if="styleKey === VARIANTS.FORM_AND_TEXT"
            :class="{
                [$style['block']]: true,
                [$style['block_with-text']]: true,
                [$style['block_reversed']]: config.content_location_for_form_with_text === 'form_with_text_right',
                [$style['block_inverted']]: config.inverted_font_color
            }"
        >
            <FeedbackForm
                :pivot-id="pivotId"
                :title="config.form_heading_text"
                :text="config.form_text_text"
                :fields="config.form_fields || []"
                :button-text="config.form_button_label"
                :button-text-after-submit="config.form_button_message_after_submit"
                :class="$style['block__form']"
            />

            <div :class="$style['block__content']">
                <h2
                    :class="[$style['block__title'], 'sw-title']"
                    v-html="config.heading_text"
                />

                <div
                    :class="[$style['block__text'], 'sw-text']"
                    v-html="config.text_text"
                />
            </div>
        </div>

        <div
            v-else
            :class="[
                $style['block'],
                $style['block_without-text'],
            ]"
        >
            <FeedbackForm
                :pivot-id="pivotId"
                :title="config.form_heading_text"
                :text="config.form_text_text"
                :fields="config.form_fields || []"
                :button-text="config.form_button_label"
                :button-text-after-submit="config.form_button_message_after_submit"
            />
        </div>
    </SwContainer>
</template>

<script>
const VARIANTS = {
  FORM: 'form',
  FORM_AND_TEXT: 'form_with_text'
}

export default {
  name: 'BlockFeedbackForm',

  components: {
    FeedbackForm: () => import('@@/website/components/common/FeedbackForm')
  },

  props: {
    pivotId: {
      type: [Number, String],
      default: null
    },

    styleKey: {
      type: String,
      default: ''
    },

    config: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    VARIANTS: () => VARIANTS
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.block {
  display: flex;
  align-items: center;
  margin: auto 0;

  &_with-text {
    @media screen and (max-width: @bp-mobile-small) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &_without-text {
    flex-direction: column;
    align-self: center;
    width: 100%;
    max-width: 522px;
  }

  &__form {
    margin-right: auto;

    @media screen and (max-width: @bp-mobile-small) {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 48px;
    }
  }

  &_reversed &__form {
    order: 1;
    margin-left: auto;
    margin-right: 0;

    @media screen and (max-width: @bp-mobile-big) {
      margin-left: auto;
    }

    @media screen and (max-width: @bp-mobile-small) {
      margin-top: 48px;
      margin-left: 0;
      margin-bottom: 0;
    }
  }

  &__content {
    max-width: 516px;
    width: 100%;
    margin-left: 75px;

    @media screen and (max-width: @bp-mobile-small) {
      max-width: 100%;
      margin-left: 0;
    }
  }

  &_reversed &__content {
    margin-left: 0;
    margin-right: 75px;

    @media screen and (max-width: @bp-mobile-small) {
      margin-right: 0;
    }
  }

  &__title {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    margin-top: 0;
    margin-bottom: 24px;
    @media screen and (max-width: @bp-mobile-small) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  &__text {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &_inverted &__title,
  &_inverted &__text {
    color: #FFFFFF;
  }
}
</style>
